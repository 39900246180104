export default `<div class="ngeo-grid-table-container">
  <table
    float-thead="$ctrl.floatTheadConfig"
    ng-model="$ctrl.configuration.data"
    class="table table-bordered table-striped table-hover"
  >
    <thead class="table-header">
      <tr>
        <th
          ng-repeat="columnDefs in $ctrl.configuration.columnDefs"
          ng-click="$ctrl.sort(columnDefs.name)"
          ng-bind-html="columnDefs.name | ngeoTrustHtml | translate"
        >
          columnDefs.name
          <i ng-show="$ctrl.sortedBy !== columnDefs.name" class="fa-solid fa-fw"></i>
          <i
            ng-show="$ctrl.sortedBy === columnDefs.name && $ctrl.sortAscending === true"
            class="fa-solid fa-caret-up"
          ></i>
          <i
            ng-show="$ctrl.sortedBy === columnDefs.name && $ctrl.sortAscending === false"
            class="fa-solid fa-caret-down"
          ></i>
        </th>
        <th ng-if="$ctrl.configuration.data[0]['EGRIS_EGRID']">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr
        ng-repeat="attributes in $ctrl.configuration.data"
        ng-class="['row-' + $ctrl.configuration.getRowUid(attributes), $ctrl.configuration.isRowSelected(attributes) ? 'ngeo-grid-active': '']"
        ng-mousedown="$ctrl.preventTextSelection($event)"
      >
        <td
          ng-repeat="columnDefs in $ctrl.configuration.columnDefs"
          ng-bind-html="attributes[columnDefs.name] | removeCDATA | ngeoTrustHtmlAuto"
        ></td>
        <td ng-if="attributes['EGRIS_EGRID']">
          <a class="owner-link" href="javascript:void(0);" onclick="window.open(window.location.origin + '/owner?egrid=' + this.getAttribute('data-egrid'), '_blank', 'width=800,height=600,scrollbars=yes,resizable=yes')" data-egrid="{{attributes['EGRIS_EGRID']}}">Link zur Eigentümerabfrage</a>
        </td>
      </tr>
    </tbody>
  </table>
</div>`;
